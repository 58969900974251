<template>
  <div class="pl-3 pt-3">
    <Header
      :status="filter.status"
      :active-count="activeCount"
      :archived-count="archivedCount"
      @update-status="updateFilterStatus"
      @create-new-tag="onCreateTag"
    />

    <div
      v-if="loaderState"
      class="project-placeholder-height d-flex align-center"
    >
      <Loader />
    </div> 
    <template v-else-if="activeCount == 0 && filter.status != 'archived' && !isFilter && !tableLoadingState">
      <div class="mt-3 mb-0 white rounded-lg mx-0 app-height-global d-flex justify-center align-center">
        <ActiveEmptyState
          :image-src="require('@/assets/png/empty-tags.png')"
          image-max-width="323px"
          :title="$t('tagsPlaceholder.title')"
          :button-text="$t('tagsPlaceholder.description.highlight')"
          button-color="primary"
          @button-click="onCreateTag"
        >
          <template #description>
            <p class="mb-0 mt-3">
              {{ $t('tagsPlaceholder.description.part1') }}
            </p>
            <p class="mb-0">
              <strong>{{ `"${$t('tagsPlaceholder.description.highlight')}"` }}</strong>{{ $t('tagsPlaceholder.description.part2') }}
            </p>
            <p class="mb-0">
              {{ $t('tagsPlaceholder.description.part3') }}
            </p>
          </template>
        </ActiveEmptyState>
      </div>
    </template>

    <template v-else-if="archivedCount == 0 && filter.status != 'active' && !isFilter && !tableLoadingState">
      <div class="mt-3 mb-0 white rounded-lg mx-0 app-height-global d-flex justify-center align-center">
        <ArchivedEmptyState
          :image-src="require('@/assets/png/empty-tags.png')"
          image-max-width="323px"
          :title="$t('archived_empty_state.title', { name: $t('tags') })"
        >
          <template #description>
            <p class="mb-0 mt-3">
              {{ $t('archived_empty_state.description.part1', { name: $t('tags') }) }}
            </p>
            <p class="mb-0">
              {{ $t('projects.archived_empty_state.description.part2') }}
            </p>
          </template>
        </ArchivedEmptyState>
      </div>
    </template>
    <v-card
      v-else
      class="py-6 px-6 mt-3 app-height-global"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <template>
        <v-row
          justify="space-between"
          class="align-center"
        >
          <v-col
            cols="12"
            md="auto"
            class="d-flex align-center"
          >
            <SearchComponent
              class="mr-3"
              :search="filter.name"
              :placeholder="$t('placeHolder.searchByName')"
              @update:search="filter.name = $event"
            />

            <FilterDialog @update-filter-types="updateFilterTypes" />
          </v-col>

          <v-col
            cols="12"
            md="auto"
          >
            <v-row
              justify="end"
              class="align-center"
            >
              <SettingsMenu 
                table-type="tags"
              />
            </v-row>
          </v-col>
        </v-row>

        <TagTable
          :headers="filteredHeaders"
          :items="filteredTags"
          item-key="uid"
          @edit-tag="onEditTag"
          @archive-tag="onArchiveTag"
          @delete-tag="onDeleteTag"
        />
      </template>
    </v-card>
    <CreateUpdateDialog
      v-model="showCreateUpdateDialog"
      :data="selectedTag"
      @create-new-tag="createTag"
      @update-tag="updateTag"
      @close-dialog="showCreateUpdateDialog = false"
    />

    <ArchiveConfirmDialog
      v-model="showArchiveConfirmDialog"
      :tag-name="selectedTag.name"
      :filter-status="filter.status"
      @archive-tag="archiveTag"
    />

    <DeleteConfirmDialog
      v-model="showDeleteConfirmDialog"
      :tag="selectedTag"
      @delete-tag="deleteTag"
    />
  </div>
</template>

<script>
import * as _ from "lodash";
import { createNamespacedHelpers, mapActions } from "vuex";

import makeTagService from "@/services/api/tag";

import Header from "@/components/Admin/Tag/Header";
import FilterDialog from "@/components/Admin/Tag/FilterDialog.vue";
import SearchComponent from "@/components/Project/SearchComponent.vue";
import SettingsMenu from "@/components/Project/SettingsMenu.vue";
import TagTable from "@/components/Admin/Tag/TagTable.vue";
import CreateUpdateDialog from "@/components/Admin/Tag/CreateUpdateDialog.vue";
import ArchiveConfirmDialog from "@/components/Admin/Tag/ArchiveConfirmDialog.vue";
import DeleteConfirmDialog from "@/components/Admin/Tag/DeleteConfirmDialog.vue";
import { mapGetters } from 'vuex';
import { handleNetworkStatusError } from "@/mixins/redirect";
import { showSuccessToast, showErrorToast } from "@/utils/toast";

import { sleep } from '@/utils/util';
import Loader from '@/components/base/Loader.vue';
import ActiveEmptyState from '@/components/base/ActiveEmptyState.vue';
import ArchivedEmptyState from '@/components/base/ArchivedEmptyState.vue';

const { mapState } = createNamespacedHelpers("user");

export default {
  name: "Tags",

  components: {
    Header,
    FilterDialog,
    SearchComponent,
    SettingsMenu,
    TagTable,
    CreateUpdateDialog,
    ArchiveConfirmDialog,
    DeleteConfirmDialog,
    Loader,
    ActiveEmptyState,
    ArchivedEmptyState,
  },
  mixins: [handleNetworkStatusError],

  data() {
    return {
      filter: {
        name: "",
        status: "active",
        entityTypes: [],
      },
      originalTags: [],
      filteredTags: [],
      headers: [],
      isLoading: false,
      showCreateUpdateDialog: false,
      showArchiveConfirmDialog: false,
      showDeleteConfirmDialog: false,
      selectedTag: {
        uid: "",
        name: "",
        description: "",
        entityTypes: [],
      },
      isFilter: false,
      tableLoadingState: false,
      loaderState: false,
    };
  },

  computed: {
    ...mapGetters({
      dynamicHeaders:'headers/dynamicHeaders'
    }),
    ...mapState(["currentAccount"]),
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.value != "actions");
      return filtered;
    },
    filteredHeaders() {
        const filtered = this.filteredMenuHeaders.filter((header) => header.checked);
        return filtered;
    },
    activeCount() {
      return this.originalTags.filter((item) => !item.archivedAt).length;
    },

    archivedCount() {
      return this.originalTags.filter((item) => !!item.archivedAt).length;
    },

    hasTags() {
      return this.originalTags.length > 0;
    },
    hasNoTags() {
      return this.originalTags.length === 0;
    },
  },

  watch: {
    "filter.name": {
      handler: _.debounce(function () {
        this.filterTags();
      }, 500),
    },
  },

  created() {
    if(!this.dynamicHeaders.tags) {
      this.initializeHeaders({ type: 'tags' });
    }
    this.headers = this.dynamicHeaders.tags;
  },

  mounted() {
    let handle = this.$route.params.handle;
    this.init(handle);
  },
  
  async beforeRouteUpdate(to, from, next) {
    const handle = to.params.handle;
    if (handle && handle !== from.params.handle) {
      try {
        await this.init(handle);
        next();
      } catch (error) {
        next();
      }
    } else {
      next();
    }
  },

  methods: {
    ...mapActions("headers", ['initializeHeaders']),
    async init(handle) {
      
      this.loaderState = false;
      this.tableLoadingState = true;
      let loaderStateTimeout = null;

      loaderStateTimeout = setTimeout(() => {
        this.loaderState = true;
      }, 1000);

      try {
        await this.getTags(handle);
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'test runs' });
      } finally {
        clearTimeout(loaderStateTimeout); 
        const hideLoader = async () => {

          await sleep(1000); 
          this.loaderState = false; 
          this.tableLoadingState = false;
        };
        hideLoader(); 
      }
    },
    async getTags(handle) {
      const tagService = makeTagService(this.$api);

      try {
        this.isLoading = true;
        const response = await tagService.getTags(handle);
        if (response.status == 200) {
          this.originalTags = response.data;
        }
      } catch (err) {
        this.redirectOnError(err.response.status);
        showErrorToast(this.$swal, "fetchError", { item: "tags" });
      } finally {
        this.isLoading = false;
      }

      this.filterTags();
    },

    updateFilterStatus(value) {
      this.filter.status = value;

      this.filterTags();
    },

    updateFilterTypes(value) {
      this.filter.entityTypes = value;

      this.filterTags();
    },

    filterTags() {
      let filteredTags = _.cloneDeep(this.originalTags);

      if (this.filter.name) {
        const searchName = this.filter.name.toLowerCase();
        filteredTags = filteredTags.filter((item) =>
          item.name.toLowerCase().includes(searchName)
        );
      }

      if (this.filter.status) {
        filteredTags = filteredTags.filter(
          (item) =>
            (this.filter.status === "active" && !item.archivedAt) ||
            (this.filter.status === "archived" && item.archivedAt)
        );
      }

      if (this.filter.entityTypes.length > 0) {
        filteredTags = filteredTags.filter((item) =>
          this.filter.entityTypes.some((tagType) => {
            return item.entityTypes && item.entityTypes.includes(tagType.name);
          })
        );
      }

      this.filteredTags = filteredTags;
    },

    onCreateTag() {
      this.selectedTag = {
        uid: "",
        name: "",
        description: "",
        entityTypes: [],
      };
      this.showCreateUpdateDialog = true;
    },

    async createTag(tag) {
      this.showCreateUpdateDialog = false;

      const tagService = makeTagService(this.$api);
      this.isLoading = true;
    
      try {
        const payload = {
          name: tag.name,
          description: tag.description,
          entityTypes: tag.entityTypes,
        }

        const response = await tagService.createTag(this.currentAccount.handle, payload);
        this.originalTags.push(response.data);
        showSuccessToast(this.$swal, "createSuccess", { item: "Tag" });
      } catch (err) {
        showErrorToast(this.$swal, "createError", { item: "Tag" });
      } finally {
        this.isLoading = false;
      }

      this.filterTags();
    },

    onEditTag(tag) {
      this.selectedTag = {
        uid: tag.uid,
        name: tag.name,
        description: tag.description,
        entityTypes: tag.entityTypes,
        archivedAt: tag.archivedAt,
      };

      this.showCreateUpdateDialog = true;
    },

    async updateTag(tag) {
      this.showCreateUpdateDialog = false;
      const tagService = makeTagService(this.$api);
      this.isLoading = true;
      const sentData = {
        ...tag,
        entityTypes: tag.entityTypes,
      }
      try {
        const response = await tagService.updateTag(this.currentAccount.handle, sentData);

        this.originalTags = this.originalTags.map((item) => {
          if (item.uid !== tag.uid) {
            return item;
          }

          return response.data;
        });
        showSuccessToast(this.$swal, "updateSuccess", { item: "Tag" });
      } catch (err) {
        showErrorToast(this.$swal, "updateError", { item: "Tag" });
        throw new Error(err);
      } finally {
        this.isLoading = false;
      }

      this.filterTags();
    },

    onArchiveTag(tag) {
      this.selectedTag = {
        uid: tag.uid,
        name: tag.name,
        description: tag.description,
        entityTypes: tag.entityTypes,
        archived: this.filter.status === "active" ? true : new Date(),
      };

      this.showArchiveConfirmDialog = true;
    },

    async archiveTag() {
      this.showArchiveConfirmDialog = false;
      try {
        await this.updateTag(this.selectedTag);
        showSuccessToast(this.$swal, "archiveSuccess", { item: "Tag" });
      } catch (err) {
        showErrorToast(this.$swal, "archiveError", { item: "Tag" });
      }
    },

    onDeleteTag(tag) {
      this.selectedTag = {
        uid: tag.uid,
        name: tag.name,
        description: tag.description,
        entityTypes: tag.entityTypes,
        count: tag.count,
        archived: tag.archivedAt,
      };

      this.showDeleteConfirmDialog = true;
    },

    async deleteTag() {
      this.showDeleteConfirmDialog = false;
      const tagService = makeTagService(this.$api);

      try {
        await tagService.deleteTag(this.currentAccount.handle, this.selectedTag.uid);
        this.originalTags = this.originalTags.filter(
          (item) => item.uid !== this.selectedTag.uid
        );
        showSuccessToast(this.$swal, "deleteSuccess", { item: "Tag" });
      } catch (err) {
        showErrorToast(this.$swal, "deleteError", { item: "Tag" });
      } finally {
        this.isLoading = false;
      }

      this.filterTags();
    },
  },
};
</script>
